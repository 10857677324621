var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('section',{attrs:{"id":"account_member"}},[_c('div',{staticClass:"w1300 layout_container"},[_vm._m(0),_c('div',{staticClass:"form_box"},[_c('form',{attrs:{"id":"form_account_member","action":"","method":"post"}},[_vm._m(1),_c('div',{staticClass:"form_interactive"},[_c('Password',{staticClass:"w100",attrs:{"item":_vm.inputFormat.originalPasswordInput},model:{value:(_vm.userUpdate.current_password),callback:function ($$v) {_vm.$set(_vm.userUpdate, "current_password", $$v)},expression:"userUpdate.current_password"}}),_c('Password',{staticClass:"w50",attrs:{"item":_vm.inputFormat.newPasswordInput,"rules":[
                function (val) { return val.length > 5 || '長度錯誤!'; },
                [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
              ]},model:{value:(_vm.userUpdate.password),callback:function ($$v) {_vm.$set(_vm.userUpdate, "password", $$v)},expression:"userUpdate.password"}}),_c('Password',{staticClass:"w50",attrs:{"item":_vm.inputFormat.confirmPasswordInput,"rules":[
                function (val) { return val.length > 5 || '長度錯誤!'; },
                [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
              ]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"small w100 txt-red"},[_vm._v(_vm._s(_vm.confirmPasswordErrorMsg))])],1),_vm._m(2),_c('div',{staticClass:"form_interactive"},[_c('div',{staticClass:"half"},[_c('div',{staticClass:"fixed_info w33"},[_c('p',{staticClass:"fixed_info_title"},[_vm._v("姓名")]),_c('p',{staticClass:"fixed_info_content"},[_vm._v(_vm._s(_vm.fixedInfo.realname))])]),_c('div',{staticClass:"fixed_info w33"},[_c('p',{staticClass:"fixed_info_title"},[_vm._v("會員編號")]),_c('p',{staticClass:"fixed_info_content"},[_vm._v(_vm._s(_vm.fixedInfo.no))])]),_c('div',{staticClass:"fixed_info w33"},[_c('p',{staticClass:"fixed_info_title"},[_vm._v("性別")]),_c('p',{staticClass:"fixed_info_content"},[_vm._v(" "+_vm._s(_vm.userGender(_vm.fixedInfo.gender, _vm.fixedInfo.id_number))+" ")])])]),_c('div',{staticClass:"half"},[_c('div',{staticClass:"fixed_info w50"},[_c('p',{staticClass:"fixed_info_title"},[_vm._v("出生年月日")]),_c('p',{staticClass:"fixed_info_content"},[_vm._v(_vm._s(_vm.fixedInfo.birthday))])]),_c('div',{staticClass:"fixed_info w50"},[_c('p',{staticClass:"fixed_info_title"},[_vm._v("身分證字號/居留證號碼")]),_c('p',{staticClass:"fixed_info_content"},[_vm._v(_vm._s(_vm.fixedInfo.id_number))])])]),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.mobileInput,"rules":[
                function (val) { return !!val || '必填欄位！'; },
                [/^09\d{8}$/, '手機格式錯誤']
              ],"maxlength":10},model:{value:(_vm.userUpdate.phone),callback:function ($$v) {_vm.$set(_vm.userUpdate, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.phone"}}),_c('div',{staticClass:"half"},[_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.telePublicInput,"maxlength":10},model:{value:(_vm.userUpdate.memo.office_tel),callback:function ($$v) {_vm.$set(_vm.userUpdate.memo, "office_tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.memo.office_tel"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.teleHomeInput,"maxlength":10},model:{value:(_vm.userUpdate.tel),callback:function ($$v) {_vm.$set(_vm.userUpdate, "tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.tel"}})],1),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.emailInput,"rules":[
                function (val) { return !!val || '必填欄位！'; },
                [_vm.emailRegex, '信箱格式錯誤'] ]},model:{value:(_vm.userUpdate.email),callback:function ($$v) {_vm.$set(_vm.userUpdate, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.email"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.lineInput},model:{value:(_vm.userUpdate.memo.line_id),callback:function ($$v) {_vm.$set(_vm.userUpdate.memo, "line_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.memo.line_id"}}),_c('Address',{staticClass:"w100",attrs:{"title":'戶籍地址',"city":_vm.userUpdate.city,"area":_vm.userUpdate.area,"zipcode":_vm.userUpdate.zipcode,"addr":_vm.userUpdate.addr,"required":true},on:{"update:city":function($event){return _vm.$set(_vm.userUpdate, "city", $event)},"update:area":function($event){return _vm.$set(_vm.userUpdate, "area", $event)},"update:zipcode":function($event){return _vm.$set(_vm.userUpdate, "zipcode", $event)},"update:addr":function($event){return _vm.$set(_vm.userUpdate, "addr", $event)}}})],1),_vm._m(3),_c('div',{staticClass:"form_interactive"},[_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.departmentInput,"rules":[
                function (val) { return !!val || '必填欄位！'; },
                function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.userUpdate.jobs.department),callback:function ($$v) {_vm.$set(_vm.userUpdate.jobs, "department", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.jobs.department"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.hospitalInput,"rules":[
                function (val) { return !!val || '必填欄位！'; },
                function (val) { return val.length > 1 || '長度錯誤!'; } ]},model:{value:(_vm.userUpdate.jobs.hospital_name),callback:function ($$v) {_vm.$set(_vm.userUpdate.jobs, "hospital_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.jobs.hospital_name"}}),_c('Address',{staticClass:"w100",attrs:{"title":'院所地址',"city":_vm.userUpdate.jobs.hospital_city,"area":_vm.userUpdate.jobs.hospital_area,"zipcode":_vm.userUpdate.jobs.hospital_zipcode,"addr":_vm.userUpdate.jobs.hospital_addr},on:{"update:city":function($event){return _vm.$set(_vm.userUpdate.jobs, "hospital_city", $event)},"update:area":function($event){return _vm.$set(_vm.userUpdate.jobs, "hospital_area", $event)},"update:zipcode":function($event){return _vm.$set(_vm.userUpdate.jobs, "hospital_zipcode", $event)},"update:addr":function($event){return _vm.$set(_vm.userUpdate.jobs, "hospital_addr", $event)}}}),_c('TextInput',{staticClass:"w100",attrs:{"item":_vm.inputFormat.feeTitleInput},model:{value:(_vm.userUpdate.memo.fee_title),callback:function ($$v) {_vm.$set(_vm.userUpdate.memo, "fee_title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userUpdate.memo.fee_title"}})],1),_c('div',{staticClass:"form_submit txt-center"},[_c('button',{staticClass:"btn p",class:{ btnDisable: _vm.isBtnDisable },attrs:{"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("送出")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_title_box mg-b-50"},[_c('h3',{staticClass:"main_title txt-bold"},[_vm._v("會員資料變更")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/statics/img/index/icon_title2.svg"),"alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form_description"},[_c('div',{staticClass:"main_description_box mg-tb-10"},[_c('h4',{staticClass:"main_description_title txt-light_green txt-bold"},[_vm._v("申請辦法")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form_description"},[_c('div',{staticClass:"main_description_box mg-tb-10"},[_c('h4',{staticClass:"main_description_title txt-light_green txt-bold"},[_vm._v("基本資料")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form_description"},[_c('div',{staticClass:"main_description_box mg-tb-10"},[_c('h4',{staticClass:"main_description_title txt-light_green txt-bold"},[_vm._v("服務資料")])])])}]

export { render, staticRenderFns }