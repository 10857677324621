<template>
  <main>
    <section id="account_member">
      <div class="w1300 layout_container">

        <!-- 內頁主標題 -->
        <div class="main_title_box mg-b-50">
          <h3 class="main_title txt-bold">會員資料變更</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- form area -->
        <div class="form_box">
          <form id="form_account_member" action="" method="post">

            <!-- 申請辦法 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">申請辦法</h4>
              </div>
              <!-- <span class="small txt-red">{{ passwordErrorMsg }}</span> -->
            </div>
            <div class="form_interactive">
              <Password
                class="w100"
                v-model="userUpdate.current_password"
                :item="inputFormat.originalPasswordInput"
              />
              <Password
                class="w50"
                v-model="userUpdate.password"
                :item="inputFormat.newPasswordInput"
                :rules="[
                  val => val.length > 5 || '長度錯誤!',
                  [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
                ]"
              />
              <Password
                class="w50"
                v-model="confirmPassword"
                :item="inputFormat.confirmPasswordInput"
                :rules="[
                  val => val.length > 5 || '長度錯誤!',
                  [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
                ]"
              />
              <span class="small w100 txt-red">{{ confirmPasswordErrorMsg }}</span>
            </div>

            <!-- 基本資料 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
              </div>
            </div>
            <div class="form_interactive">
              <div class="half">
                <div class="fixed_info w33">
                  <p class="fixed_info_title">姓名</p>
                  <p class="fixed_info_content">{{ fixedInfo.realname }}</p>
                </div>
                <div class="fixed_info w33">
                  <p class="fixed_info_title">會員編號</p>
                  <p class="fixed_info_content">{{ fixedInfo.no }}</p>
                </div>
                <div class="fixed_info w33">
                  <p class="fixed_info_title">性別</p>
                  <p class="fixed_info_content">
                    {{ userGender(fixedInfo.gender, fixedInfo.id_number) }}
                  </p>
                </div>
              </div>
              <div class="half">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">出生年月日</p>
                  <p class="fixed_info_content">{{ fixedInfo.birthday }}</p>
                </div>
                <div class="fixed_info w50">
                  <p class="fixed_info_title">身分證字號/居留證號碼</p>
                  <p class="fixed_info_content">{{ fixedInfo.id_number }}</p>
                </div>
              </div>
              <TextInput
                class="w50"
                v-model.trim="userUpdate.phone"
                :item="inputFormat.mobileInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^09\d{8}$/, '手機格式錯誤']
                ]"
                :maxlength="10"
              />
              <div class="half">
                <TextInput
                  class="w50"
                  v-model.trim="userUpdate.memo.office_tel"
                  :item="inputFormat.telePublicInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="userUpdate.tel"
                  :item="inputFormat.teleHomeInput"
                  :maxlength="10"
                />
              </div>
              <TextInput
                class="w50"
                v-model.trim="userUpdate.email"
                :item="inputFormat.emailInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [emailRegex, '信箱格式錯誤'],
                ]"
              />
              <TextInput
                class="w50"
                v-model.trim="userUpdate.memo.line_id"
                :item="inputFormat.lineInput"
              />
              <Address
                class="w100"
                :title="'戶籍地址'"
                :city.sync="userUpdate.city"
                :area.sync="userUpdate.area"
                :zipcode.sync="userUpdate.zipcode"
                :addr.sync="userUpdate.addr"
                :required="true"
              />
            </div>

            <!-- 服務資料 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
              </div>
            </div>
            <div class="form_interactive">
              <TextInput
                class="w50"
                v-model.trim="userUpdate.jobs.department"
                :item="inputFormat.departmentInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />
              <TextInput
                class="w50"
                v-model.trim="userUpdate.jobs.hospital_name"
                :item="inputFormat.hospitalInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />
              <Address
                class="w100"
                :title="'院所地址'"
                :city.sync="userUpdate.jobs.hospital_city"
                :area.sync="userUpdate.jobs.hospital_area"
                :zipcode.sync="userUpdate.jobs.hospital_zipcode"
                :addr.sync="userUpdate.jobs.hospital_addr"
              />
              <TextInput
                class="w100"
                v-model.trim="userUpdate.memo.fee_title"
                :item="inputFormat.feeTitleInput"
              />
            </div>

            <div class="form_submit txt-center">
              <button
                type="button"
                class="btn p"
                :class="{ btnDisable: isBtnDisable }"
                @click="onSubmit"
              >送出</button>
            </div>

          </form>
        </div>

      </div>
    </section>

  </main>
</template>

<script>
/** Libraries */
import _ from 'lodash';

/** Components */
import Password from '@/components/form/Password.vue';
import TextInput from '@/components/form/TextInput.vue';
import Address from '@/components/form/Address.vue';

/** Functions */
import { userInfo, userUpdate } from '@/lib/http';
import { mapGetters, mapActions } from 'vuex';
import { emailRegex } from '@/lib/const';
import { userGender } from '@/lib/public';

export default {
  name: 'AccountIndex',
  data() {
    return {
      /**
       * Static Data
       */
      emailRegex,
      confirmPassword: '',
      checkUserUpdate: '',
      isBtnDisable: false,
      homeAddressErrorMsg: '',
      hospitalAddressErrorMsg: '',
      passwordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      inputFormat: {
        originalPasswordInput: {
          label: '原始密碼',
          reminder: '',
          placeholder: '請輸入您的原始密碼',
        },
        newPasswordInput: {
          label: '新密碼',
          reminder: '密碼長度請設定6位以上英數混合',
          placeholder: '請輸入新密碼',
        },
        confirmPasswordInput: {
          label: '確認密碼',
          reminder: '',
          placeholder: '請再次輸入新密碼',
        },
        mobileInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        teleHomeInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        lineInput: {
          label: 'LINE ID',
          type: 'text',
          placeholder: '請輸入LINE ID',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        feeTitleInput: {
          label: '收據抬頭',
          type: 'text',
          placeholder: '請輸入抬頭',
          required: false,
        },
      },
      /**
       * Axios Response Data
       */
      fixedInfo: {
        realname: '',
        no: '',
        gender: '',
        birthday: '',
        id_number: '',
      },
      userUpdate: {
        current_password: '',
        password: '',
        phone: '',
        tel: '',
        email: '',
        memo: {
          line_id: '',
          office_tel: '',
          fee_title: '',
        },
        city: '',
        area: '',
        zipcode: '',
        addr: '',
        jobs: {
          department: '',
          hospital_name: '',
          hospital_city: '',
          hospital_area: '',
          hospital_zipcode: '',
          hospital_addr: '',
        },
      },
    };
  },
  created() {
    /**
     * 會員基本資料
     */
    if (this.hasToken) {
      userInfo().then((result) => {
        this.fixedInfo.realname = _.get(result, 'realname', '');
        this.fixedInfo.no = _.get(result, 'no', '');
        this.fixedInfo.birthday = _.get(result, 'birthday', '');
        this.fixedInfo.id_number = _.get(result, 'id_number', '');
        this.fixedInfo.gender = _.get(result, 'gender', '');
        this.userUpdate.phone = _.get(result, 'phone', '').replace(/-/g, '');
        this.userUpdate.tel = _.get(result, 'tel', '');
        this.userUpdate.memo.office_tel = _.get(result, 'memo.office_tel', '');
        // if (result.email) {
        //   userUpdate.email = result.email;
        // }
        this.userUpdate.email = _.get(result, 'email', '');
        this.userUpdate.memo.line_id = _.get(result, 'memo.line_id', '');
        this.userUpdate.city = _.get(result, 'city', '');
        this.userUpdate.area = _.get(result, 'area', '');
        this.userUpdate.zipcode = _.get(result, 'zipcode', '');
        this.userUpdate.addr = _.get(result, 'addr', '');
        this.userUpdate.jobs.department = _.get(result, 'jobs.department', '');
        this.userUpdate.jobs.hospital_name = _.get(result, 'jobs.hospital_name', '');
        this.userUpdate.jobs.hospital_city = _.get(result, 'jobs.hospital_city', '');
        this.userUpdate.jobs.hospital_area = _.get(result, 'jobs.hospital_area', '');
        this.userUpdate.jobs.hospital_zipcode = _.get(result, 'jobs.hospital_zipcode', '');
        this.userUpdate.jobs.hospital_addr = _.get(result, 'jobs.hospital_addr', '');
        this.userUpdate.memo.fee_title = _.get(result, 'memo.fee_title', '');

        /** 複製一份送出資料，若完全沒有更改任何資料卻想送出時，根據此拷貝來將其擋下 */
        this.checkUserUpdate = JSON.parse(JSON.stringify(this.userUpdate));
      });
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
    ]),
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (this.userUpdate.current_password && this.userUpdate.password && this.confirmPassword) {
        if (this.userUpdate.password === this.confirmPassword) {
          this.confirmPasswordErrorMsg = '';
        } else {
          errorList.push(true);
          this.confirmPasswordErrorMsg = '新密碼及確認密碼應相符';
        }
      } else if (!this.userUpdate.current_password
              && !this.userUpdate.password
              && !this.confirmPassword) {
        this.passwordErrorMsg = '';
      } else {
        errorList.push(true);
        this.passwordErrorMsg = '新密碼及確認密碼應相符';
      }
      if (errorList.indexOf(true) === -1) {
        if (JSON.stringify(this.userUpdate) !== JSON.stringify(this.checkUserUpdate)) {
          this.isBtnDisable = true;
          this.passwordErrorMsg = '';
          userUpdate(this.userUpdate).then((result) => {
            if (result.data.message === 'success') {
              this.openModal({
                message: '會員資料已更新',
              });

              /** 送出後，拷貝一份新資料作為 */
              this.checkUserUpdate = JSON.parse(JSON.stringify(this.userUpdate));

              /** 返回頁面頂端 */
              window.scrollTo({
                top: 0,
                // behavior: 'smooth',
              });
            } else {
              this.openModal({
                message: result.data.message,
              });
            }
            this.isBtnDisable = false;
          });
        } else {
          this.openModal({
            message: '無更新資料，返回表單',
          });
        }
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
    userGender,
  },
  components: {
    Password,
    // eslint-disable-next-line vue/no-unused-components
    TextInput,
    Address,
  },
};
</script>
